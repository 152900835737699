<template>
  <div class="container-height">
    <!-- Nav Header -->
    <div class="space-y-2 p-6 border-b border-neutral-50">
      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-col gap-y-2">
          <div class="flex flex-row gap-x-3 items-center">
            <ic-arrow
              v-if="selectedMonitoringGroup"
              class="cursor-pointer hover:text-bb-brand-purple duration-300 ease-linear"
              @click="backToCMS"
            />
            <h3 class="h3">All Monitoring Groups</h3>
          </div>
          <p class="text-sm font-normal">Access and edit all your monitoring groups at any time</p>
        </div>
        <merge-button-round
          button-type="brand-purple"
          class="mt-5 mr-5"
          @click="createNewGroup"
          >New Group
          <template #left-icon>
            <ic-plus
              :size="16"
              class="my-auto"
            />
          </template>
        </merge-button-round>
      </div>
    </div>
    <!-- Template -->
    <all-monitoring-groups
      :monitoring-groups="computedMonitoringGroups"
      :selected-monitoring-group="computedSelectedMonitoringGroup"
      @show-selected-monitoring-settings="showSelectedMonitoringSettings"
      @select-monitoring-group="selectMonitoringGroup"
    />

    <!-- Modals -->
    <bb-base-modal
      v-if="visibleModal"
      :width="modalConfig.width"
      :height="modalConfig.height"
      @close="closeModal"
    >
      <template #container>
        <selected-monitoring-settings
          v-if="visibleModal === 'selected-monitoring-settings'"
          @close-modal="closeModal"
        />
      </template>
    </bb-base-modal>
  </div>
</template>

<script>
import AllMonitoringGroups from './AllMonitoringGroups.vue'
import BbBaseModal from '@/components/modals/brightbid/BbBaseModal.vue'

import IcArrow from '@/components/icon/brightbid/ic-arrow.vue'
import SelectedMonitoringSettings from '../components/modals/SelectedMonitoringSettings.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AllGroups',
  components: {
    AllMonitoringGroups,
    BbBaseModal,
    SelectedMonitoringSettings,
    IcArrow,
  },
  data() {
    return {
      hasMonitoringJob: true,
      visibleModal: null,
      modalConfig: {
        width: '',
        height: '',
      },
    }
  },
  computed: {
    ...mapState('competitorMonitoringV2', ['monitoringGroups', 'selectedMonitoringGroup']),
    ...mapState('search', ['campaignsAll']),
    computedMonitoringGroups() {
      return this.monitoringGroups.map(group => {
        const campaign = this.campaignsAll.find(campaign => campaign.campaign_id === group?.googleCampaignId)
        return {
          ...group,
          campaignName: campaign?.name ?? null,
        }
      })
    },
    computedSelectedMonitoringGroup() {
      return this.computedMonitoringGroups.find(group => group.id === this.selectedMonitoringGroup?.id)
    },
  },
  methods: {
    ...mapActions('competitorMonitoringV2', ['loadSelectedMonitoringGroup']),
    //TODO: Add actual logic for this method
    createNewGroup() {
      console.log('New group created')
    },
    showSelectedMonitoringSettings() {
      this.visibleModal = 'selected-monitoring-settings'
      this.modalConfig = {
        width: '800px',
        height: '720px',
      }
    },
    closeModal() {
      this.visibleModal = null
    },
    backToCMS() {
      if (!this.selectedMonitoringGroup) return
      this.$router.push({ name: 'competitor-monitoring-v2' })
    },
    async selectMonitoringGroup(monitoringGroup) {
      await this.loadSelectedMonitoringGroup(monitoringGroup)
    },
  },
}
</script>

<style scoped lang="scss">
.container-height {
  min-height: calc(100vh - 200px);
}
</style>
