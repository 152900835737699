<template>
  <div
    class="container-height p-6 bg-neutral-0 base-scrollbar overflow-y-auto"
    style="height: calc(80vh - 50px)"
  >
    <div
      v-for="monitoringGroup in monitoringGroups"
      :key="monitoringGroup.id"
    >
      <monitoring-group-card
        right-button
        :monitoring-group="monitoringGroup"
        class="shadow-bb-shadow mb-4 cursor-pointer"
        :class="selectedMonitoringGroup?.id === monitoringGroup.id ? 'border border-bb-brand-purple' : null"
        @open-modal="showSettingsModal"
        @select-monitoring-group="selectMonitoringGroup"
      >
        <template #right-icon><ic-solid-cog /></template
      ></monitoring-group-card>
    </div>
  </div>
</template>

<script>
import MonitoringGroupCard from '@/views/site/search/competitor_monitoring_v2/components/MonitoringGroupCard.vue'
import IcSolidCog from '@/components/icon/brightbid/ic-solid-cog.vue'

export default {
  name: 'AllMonitoringGroups',
  components: { MonitoringGroupCard, IcSolidCog },
  props: {
    monitoringGroups: {
      type: Array,
      default: () => [],
    },
    selectedMonitoringGroup: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    showSettingsModal() {
      this.$emit('show-selected-monitoring-settings')
    },
    selectMonitoringGroup(monitoringGroup) {
      this.$emit('select-monitoring-group', monitoringGroup)
    },
  },
}
</script>

<style scoped lang="scss">
.container-height {
  min-height: calc(100vh - 410px);
}
</style>
